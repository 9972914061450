<template>
  <alert :showing="showingConfirm" :title="title">
    <p class="text-center">{{ message }}</p>
    <div class="flex w-full mt-10 -mx-2">
      <div class="w-1/2 px-2">
        <button
          @click="close(false)"
          class="w-full h-full flex items-center justify-center bg-red-200 hover:bg-red-300 text-secondary py-2 px-4 font-semibold rounded border border-red-400 hover:border-red-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
        >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M18 6L6 18M6 6l12 12"/>
          </svg>
          <span>{{ redButtonText }}</span>
        </button>
      </div>
      <div class="w-1/2 px-2">
        <button
          @click="close(true)"
          class="w-full h-full flex items-center justify-center bg-green-200 hover:bg-green-300 text-secondary py-2 px-4 font-semibold rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M20 6L9 17l-5-5"/>
          </svg>
          <span>{{ greenButtonText }}</span>
        </button>
      </div>
    </div>
  </alert>
</template>

<script>
import Alert from "@/components/Alert.vue";

export default {
  components: {
    Alert
  },
  props: {
    showingConfirm: {
      required: true,
      type: Boolean
    },
    title: {
      default: "Are you sure?",
      type: String
    },
    message: {
      required: true,
      type: String
    },
    redButtonText: {
      default: "No",
      type: String
    },
    greenButtonText: {
      default: "Yes",
      type: String
    }
  },
  methods: {
    close(result) {
      this.$emit("complete", result);
    }
  }
};
</script>