export default {
  methods: {
    async confirmAction(options) {
      return new Promise((resolve) => {
        const defaults = {
          message: "Are you sure you want to proceed?",
          header: "Confirmation Required",
          acceptLabel: "Yes",
          rejectLabel: "No",
          icon: "pi pi-exclamation-triangle",
          acceptClass: "confirm-yes",
          rejectClass: "confirm-no",
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          accept: () => resolve(true),
          reject: () => resolve(false),
        };
        const settings = { ...defaults, ...options };

        this.$confirm.require(settings);
      });
    },

    notifySuccess(message) {
      this.notify({ severity: "success", summary: "Success", detail: message });
    },

    notifyError(message) {
      this.notify({ severity: "error", summary: "Error", detail: message });
    },

    notifyWarning(message) {
      this.notify({ severity: "warn", summary: "Warning", detail: message });
    },

    notifyInfo(message) {
      this.notify({ severity: "info", summary: "Info", detail: message });
    },

    notify({ severity, summary, detail, life = 3000, position = "top-right" }) {
      this.$toast.add({ severity, summary, detail, life, position });
    },

    async handleApiRequest(promise, notifyOnSuccess = true) {
      try {
        this.isBusy = true;
        const response = await promise;
        if (notifyOnSuccess) {
          this.notifySuccess("Operation successful.");
        }
        return response;
      } catch (error) {
        this.notifyError("An error occurred. Please try again.");
        throw error; // Re-throw the error for further handling if needed
      } finally {
        this.isBusy = false;
      }
    },
  },
};
