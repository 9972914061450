<template>
  <form @submit.prevent="saveImage" class="flex flex-wrap">
    <p>Schedule or record an event for this enquiry</p>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Image Name (optional):</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="name"
        placeholder="Image Name..."
        type="text"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Image Description (optional):</label>
      <textarea
        v-model="description"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Image Description..."
        rows="10"
      ></textarea>
    </div>
    <div v-if="status == 'Create'" class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Image:</label>
      <div
        @drop.prevent="onDropFiles"
        @dragover.prevent
        @change="onFilesChange"
        class="relative w-full bge-input rounded"
      >
        <input
          multiple
          class="absolute w-full h-full opacity-0 cursor-pointer"
          type="file"
          accept="image/*"
        />
        <div class="flex items-center py-2 px-4">
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
            <path d="M14 2v6h6M12 18v-6M9 15h6" />
          </svg>
          <p>Browse Images or Drop Here</p>
          <!-- <p v-if="image.file">{{ image.file.name }}</p> -->
        </div>
      </div>
    </div>
    <div v-if="status == 'Create'" class="w-full py-2">
      <template v-for="(image, i) in tempImages">
        <div :key="i">{{ image.file.name }}</div>
      </template>
    </div>

    <div class="w-full">
      <button
        type="submit"
        class="float-right mt-5 rounded border bg-green-200 border-green-400 text-green-800 py-1 px-3 hover:bg-green-300 hover:border-green-500 hover:shadow"
      >{{ status == 'Create' ? 'Upload Images' : 'Save Image' }}</button>
    </div>
  </form>
</template>

<script>
export default {
  name: "AddEditImage",
  components: {},
  props: {
    images: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      status: "",
      name: "",
      description: "",
      tempImages: []
    };
  },
  mounted() {
    this.tempImages = this.images;
    if (this.tempImages.length == 1) {
      this.status = "Edit";
      this.name = this.tempImages[0].name;
      this.description = this.tempImages[0].description;
    } else {
      this.status = "Create";
    }
  },
  methods: {
    onDropFiles: function(e) {
      if (this.status == "Create") {
        this.handleAttachments(e.dataTransfer.files);
      }
    },
    onFilesChange: function(e) {
      if (this.status == "Create") {
        this.handleAttachments(e.target.files);
      }
    },
    handleAttachments: function(fileList) {
      console.log(fileList);
      for (let index = 0; index < fileList.length; index++) {
        this.tempImages.push({
          file: fileList[index]
        });
      }
    },
    saveImage: function() {
      this.tempImages.forEach(img => {
        img.name = this.name;
        img.description = this.description;
      });
      this.images = this.tempImages;
      this.$emit("complete", this.images);
    }
  }
};
</script>