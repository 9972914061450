<template>
  <div
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{
      'overflow-y-hidden': viewState == 'Loading',
      'overflow-y-scroll': !viewState == 'Loading',
    }"
  >
    <!-- <div
      v-show="viewState == 'Loading'"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10" />
    </div> -->
    <page-header backTo="product-index" title="Edit Product">
      <div
          class="rounded-md"
          @click="verifyPrice"
          v-html="generateVerifiedButton()"
      ></div>

      <button
          @click="createJobSheet"
          type="button"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/>
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"/>
          <path d="M9 12h6"/>
          <path d="M9 16h6"/>
        </svg>
        <span>Create Job Sheet</span>
      </button>

      <button
          @click="archiveProduct"
          type="button"
          class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M21 8v13H3V8M1 3h22v5H1zM10 12h4"/>
        </svg>
        <span>Archive</span>
      </button>
      <button
          type="button"
          @click="saveProduct"
          class="ml-4 flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8"/>
        </svg>
        <span>Save</span>
      </button>
    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div v-if="product" class="flex flex-col">
        <div class="w-full bg-white p-5 rounded shadow-md relative">
          <div class="flex justify-between -mx-5">
            <div class="w-full px-5">
              <div class="flex items-center">
                <div
                    class="inline-flex items-center rounded-lg bg-orange-200 py-1 px-2 mr-5"
                >
                  <svg
                      class="text-orange-700 stroke-current h-8 w-8 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                  >
                    <path
                        d="M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"
                    />
                    <path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12"/>
                  </svg>
                  <p class="text-orange-700 rounded-lg text-2xl">
                    {{ product.product_code }}
                  </p>
                </div>
                <h2 class="text-3xl">
                  {{ product.name }}
                  <button
                      @click="openEditPanel"
                      class="ml-4 text-secondary bg-white p-1 rounded border border-transparent hover:border-secondary active:border-secondary active:shadow-inner active:bg-gray-200 transition duration-100"
                  >
                    <svg
                        class="stroke-current h-6 w-6"
                        fill="none"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                    >
                      <path
                          d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                      />
                    </svg>
                  </button>
                </h2>
              </div>
              <p class="my-3 text-lg">{{ product.description }}</p>
              <div class="w-full flex flex-col md:flex-row md:-ml-5">
                <div
                    v-if="product.record_number"
                    class="flex items-center py-1 md:mx-5"
                >
                  <div
                      class="inline-flex items-center rounded-lg bg-orange-200 py-1 px-2"
                  >
                    <svg
                        class="text-orange-700 stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <path
                          d="M22 19a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5l2 3h9a2 2 0 012 2z"
                      />
                    </svg>
                    <span class="text-orange-700 text-sm">Record ID</span>
                  </div>
                  <p class="rounded-lg px-2 py-1">
                    {{ product.record_number }}
                  </p>
                </div>
                <div
                    v-if="product.category"
                    class="flex items-center py-1 md:mx-5"
                >
                  <div
                      class="inline-flex items-center rounded-lg bg-orange-200 py-1 px-2"
                  >
                    <svg
                        class="text-orange-700 stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <path
                          d="M22 19a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5l2 3h9a2 2 0 012 2z"
                      />
                    </svg>
                    <span class="text-orange-700 text-sm">Category</span>
                  </div>
                  <p class="rounded-lg px-2 py-1">{{ product.category }}</p>
                </div>
                <div v-if="product.type" class="flex items-center py-1 md:mx-5">
                  <div
                      class="inline-flex items-center rounded-lg bg-orange-200 py-1 px-2"
                  >
                    <svg
                        class="text-orange-700 stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                    >
                      <path
                          d="M20.59 13.41l-7.17 7.17a2 2 0 01-2.83 0L2 12V2h10l8.59 8.59a2 2 0 010 2.82zM7 7h.01"
                      />
                    </svg>
                    <span class="text-orange-700 text-sm">Type</span>
                  </div>
                  <p class="rounded-lg px-2 py-1">{{ product.type }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="w-full mb-5 flex -mx-2 mr-2">
            <button
                @click="currentTab = 'pricing'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'pricing',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'pricing',
              }"
            >
              <span>Pricing</span>
            </button>
            <button
                @click="currentTab = 'stock'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'stock',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'stock',
              }"
            >
              <span>Stock Details</span>
            </button>
            <button
                @click="currentTab = 'supplier'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'supplier',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'supplier',
              }"
            >
              <span>Supplier Details</span>
            </button>
            <button
                @click="currentTab = 'lineItems'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'lineItems',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'lineItems',
              }"
            >
              <span>Bill of Materials</span>
            </button>
            <button
                @click="currentTab = 'images'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'images',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'images',
              }"
            >
              <span>Images</span>
            </button>
            <button
                @click="currentTab = 'faqs'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'faqs',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'faqs',
              }"
            >
              <span>FAQ's</span>
            </button>

            <button
                @click="currentTab = 'service-template'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'service-template',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'service-template',
              }"
            >
              <span>Service Templates</span>
            </button>

            <button
                @click="currentTab = 'service-certificate'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'service-certificate',
                'text-gray-900 bg-gray-100 border-gray-300 hover:bg-gray-200':
                  currentTab != 'service-certificate',
              }"
            >
              <span>Certificates</span>
            </button>
          </div>

          <div v-if="currentTab == 'pricing'">
            <div class="flex flex-wrap -my-3 -mx-5 mt-6">
              <div class="w-full flex flex-col mb-3">
                <div class="flex flex-wrap">
                  <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                    <label class="uppercase font-semibold text-sm"
                    >Cost Price</label
                    >
                    <div
                        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                    >
                      <div class="flex justify-center items-center p-2">
                        <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                          <path
                              d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                          />
                        </svg>
                      </div>
                      <input
                          v-model="product.cost_price"
                          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                          type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                    <label class="uppercase font-semibold text-sm"
                    >Delivery Cost In</label
                    >
                    <div
                        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                    >
                      <div class="flex justify-center items-center p-2">
                        <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                          <path
                              d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                          />
                        </svg>
                      </div>
                      <input
                          v-model="product.delivery_in"
                          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                          type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                    <label class="uppercase font-semibold text-sm"
                    >Delivery Cost Out</label
                    >
                    <div
                        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                    >
                      <div class="flex justify-center items-center p-2">
                        <svg class="fill-current h-5 w-5" viewBox="0 0 24 24">
                          <path
                              d="M6,21V19C10,17 9.5,13 9.5,13H7V11H9.5C8.5,6.5 10,3 14,3C16,3 17,3.5 17,3.5V5.5C11,3.5 11,8 11.5,11H16V13H11.5C11.5,13 12,17 9.5,19H18V21H6Z"
                          />
                        </svg>
                      </div>
                      <input
                          v-model="product.delivery_out"
                          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                          type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap mt-5">
              <table class="table-auto w-full">
                <thead>
                <tr class="text-left border-b border-primary">
                  <th class="px-4 pb-1 uppercase font-semibold text-sm">
                    Band Code
                  </th>
                  <th class="px-4 pb-1 uppercase font-semibold text-sm">
                    Description
                  </th>
                  <th class="px-4 pb-1 uppercase font-semibold text-sm">
                    Price
                  </th>
                  <th class="px-4 pb-1 uppercase font-semibold text-sm">
                    Delivery Price
                  </th>
                  <th class="px-4 pb-1 uppercase font-semibold text-sm"></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(band, i) in product.price_bands">
                  <tr
                      :key="i"
                      class="hover:bg-orange-200 even:bg-gray-100 group"
                  >
                    <td class="px-4 py-2">{{ band.band_code }}</td>
                    <td class="px-4 py-2">{{ band.description }}</td>
                    <td class="px-4 py-2">
                      £{{ band.price ? band.price.toFixed(2) : "" }}
                    </td>
                    <td class="px-4 py-2">
                        <span v-if="band.delivery_out"
                        >£{{ band.delivery_out.toFixed(2) }}</span
                        >
                    </td>
                    <td class="px-4 py-2">
                      <button
                          type="button"
                          @click="removePriceBand(band)"
                          class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                      >
                        <svg
                            class="stroke-current h-5 w-5 mr-2"
                            fill="none"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            viewBox="0 0 24 24"
                        >
                          <path
                              d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                          />
                        </svg>
                        <span>Delete</span>
                      </button>
                      <button
                          type="button"
                          @click="editPriceBand(band)"
                          class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                      >
                        <svg
                            class="stroke-current h-5 w-5 mr-2"
                            fill="none"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            viewBox="0 0 24 24"
                        >
                          <path
                              d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                          />
                        </svg>
                        <span>Edit</span>
                      </button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <div
                  v-show="product.price_bands.length == 0"
                  class="w-full text-center py-4"
              >
                No Price Bands Added
              </div>
            </div>
            <div class="w-full flex justify-end mb-5 mt-8">
              <button
                  @click="addNewTradePrice"
                  type="button"
                  class="flex items-center bg-white text-secondary py-1 px-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
              >
                <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                >
                  <path d="M12 5v14M5 12h14"/>
                </svg>
                <span>Add Price Band</span>
              </button>
            </div>
          </div>

          <div v-if="currentTab == 'stock'">
            <div class="flex flex-wrap -my-3 -mx-5 mt-6">
              <div class="w-full flex flex-col">
                <div class="flex flex-wrap">
                  <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                    <label class="uppercase font-semibold text-sm"
                    >Warehouse</label
                    >
                    <input
                        v-model="product.location.warehouse_no"
                        class="w-full bge-input bge-input-spacing rounded"
                        type="text"
                    />
                  </div>
                  <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                    <label class="uppercase font-semibold text-sm">Rack</label>
                    <input
                        v-model="product.location.rack_no"
                        class="w-full bge-input bge-input-spacing rounded"
                        name="rack"
                        type="text"
                    />
                  </div>
                  <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                    <label class="uppercase font-semibold text-sm">Bin</label>
                    <input
                        v-model="product.location.bin_no"
                        class="w-full bge-input bge-input-spacing rounded"
                        name="bin"
                        type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
                <label class="uppercase font-semibold text-sm"
                >Reorder stock level</label
                >
                <input
                    v-model="product.reorder_level"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                />
              </div>
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
                <label class="uppercase font-semibold text-sm"
                >Quantity available</label
                >
                <input
                    v-model="product.available_qty"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                />
              </div>
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
                <label class="uppercase font-semibold text-sm"
                >Reorder quantity</label
                >
                <input
                    v-model="product.reorder_qty"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                />
              </div>
            </div>
          </div>

          <div v-if="currentTab == 'supplier'">
            <div class="flex flex-wrap -my-3 -mx-5 mt-6">
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                >Supplier Name</label
                >
                <div
                    class="fixed inset-0 overflow-hidden z-10"
                    v-if="supplierNameSelectOpen"
                    @click="supplierNameSelectOpen = false"
                ></div>
                <div class="relative">
                  <div class="relative">
                    <input
                        readonly
                        @click="openSearchSupplier"
                        v-model="product.supplier_info.name"
                        class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
                        type="text"
                    />
                    <button
                        v-if="product.supplier_info.name"
                        name="Clear Supplier"
                        @click="clearSupplier"
                        class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
                    >
                      <svg
                          class="stroke-current h-5 w-5"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                      >
                        <path d="M18 6L6 18M6 6l12 12"/>
                      </svg>
                    </button>
                  </div>
                  <div
                      v-if="supplierNameSelectOpen"
                      class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 ml-5 p-2"
                  >
                    <div class="relative flex flex-col">
                      <input
                          ref="tbSupplierSearch"
                          type="text"
                          v-model="supplierSearchTerm"
                          @keydown="searchSupplier"
                          class="bge-input bge-input-spacing rounded"
                          placeholder="Supplier name (enter 3 letters or more to search)"
                      />
                      <div
                          class="flex flex-col overflow-y-scroll bg-white mt-2"
                      >
                        <template v-for="result in supplierResults">
                          <button
                              type="button"
                              :key="result.supplier_id"
                              @click="selectSupplier(result)"
                              class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                          >
                            <span class="ml-1">{{ result.name }}</span>
                          </button>
                        </template>
                        <p class="mx-auto py-4" v-if="supplierResults == ''">
                          No suppliers match your search
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                >Supplier Part No</label
                >
                <input
                    class="bge-input bge-input-spacing rounded"
                    v-model="product.supplier_info.part_no"
                    type="text"
                />
              </div>
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                >Order Lead Time</label
                >
                <div
                    class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
                >
                  <div class="flex justify-center items-center p-2">
                    <svg
                        class="stroke-current h-5 w-5"
                        fill="none"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="12" r="10"/>
                      <path d="M12 6v6l4 2"/>
                    </svg>
                  </div>
                  <input
                      v-model="product.lead_time"
                      class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
                      type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentTab == 'lineItems'">
            <div class="flex flex-wrap -my-3 -mx-5">
              <div class="flex flex-col w-full my-3 px-5 mt-6">
                <div class="w-full">
                  <table class="table-auto w-full">
                    <thead>
                    <tr class="text-left border-b border-primary">
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Code
                      </th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Name
                      </th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Quantity
                      </th>
                      <th
                          class="px-4 pb-1 uppercase font-semibold text-sm"
                      ></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item, i) in product.line_items">
                      <tr
                          :key="i"
                          class="hover:bg-orange-200 even:bg-gray-100 group"
                      >
                        <td class="px-4 py-2">{{ item.product_code }}</td>
                        <td class="px-4 py-2">{{ item.name }}</td>
                        <td class="px-4 py-2">{{ item.qty }}</td>
                        <td class="px-4 py-2">
                          <button
                              type="button"
                              @click="removeLineItem(item)"
                              class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                                class="stroke-current h-5 w-5 mr-2"
                                fill="none"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                              <path
                                  d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                              />
                            </svg>
                            <span>Delete</span>
                          </button>
                          <button
                              type="button"
                              @click="editLineItem(item)"
                              class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                                class="stroke-current h-5 w-5 mr-2"
                                fill="none"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                              <path
                                  d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                              />
                            </svg>
                            <span>Edit</span>
                          </button>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                  <div
                      v-show="product.line_items.length == 0"
                      class="w-full text-center py-4"
                  >
                    No Items
                  </div>
                </div>

                <div class="flex justify-end mb-5 mt-8">
                  <button
                      @click="showingAddLineItemPanel = true"
                      type="button"
                      class="flex items-center bg-white text-secondary py-1 px-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
                  >
                    <svg
                        class="stroke-current h-5 w-5 mr-2"
                        fill="none"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        viewBox="0 0 24 24"
                    >
                      <path d="M12 5v14M5 12h14"/>
                    </svg>
                    <span>Add Line Item</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="currentTab == 'images'">
            <images class="mt-8" :product="product"/>
          </div>
          <div v-if="currentTab == 'faqs'">
            <FAQTable :load-faqs="loadFaqs"/>
          </div>
          <div v-if="currentTab == 'service-template'">
            <ServiceTemplate :product_id="product.product_id"/>
          </div>
          <div v-if="currentTab == 'service-certificate'">
            <ServiceCertificate :product_id="product.product_id" :productCertificates="product.certificate_type"/>
          </div>
        </div>
      </div>
      <div v-if="viewState == 'Error'">
        <div
            class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
        >
          <h2 class="text-lg font-bold">
            {{ errorMsg || "There was an error loading your product details" }}
          </h2>
          <p>
            Something went wrong loading your product, please try again. If the
            problem persists, please contact your support team.
          </p>
        </div>
      </div>
    </main>

    <portal to="overlay-outlet">
      <panel
          :showing="editDetailsPanelOpen"
          @close="editDetailsPanelOpen = false"
          title="Edit Product Details"
      >
        <edit-product-details
            :product="productDetails"
            @complete="updateProductDetails"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <panel
          :showing="showingAddTradePricePanel"
          @close="showingAddTradePricePanel = false"
          :title="tradePriceTitle"
      >
        <add-edit-trade-price
            :tradePrice="editingTradePrice"
            @complete="saveTradePrice"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <panel
          :showing="showingAddLineItemPanel"
          @close="showingAddLineItemPanel = false"
          title="Add Line Item"
      >
        <add-line-item @complete="addLineItem"/>
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <panel
          :showing="showingEditLineItemPanel"
          @close="showingEditLineItemPanel = false"
          title="Edit Line Item"
      >
        <edit-line-item :lineItem="lineItemToEdit" @complete="updateLineItem"/>
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
          :showingConfirm="confirmAlert.showing"
          :message="confirmAlert.message"
          @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import EditProductDetails from "@/components/Products/Edit/EditProductDetails.vue";
import AddEditTradePrice from "@/components/Products/TradePrices/AddEditTradePrice.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import Images from "@/components/Products/Edit/Images/Images.vue";
import Notification from "@/components/Notification.vue";
import AddLineItem from "@/components/Products/Create/AddLineItem.vue";
import EditLineItem from "@/components/Products/Create/EditLineItem.vue";
import ServiceTemplate from "@/components/Products/Service/Template.vue";
import ServiceCertificate from "@/components/Products/Service/Certificate.vue";
import FAQTable from '@/components/Faqs/FAQTable.vue';

export default {
  name: "EditProduct",
  props: {
    productCode: String,
    isCreateResult: Boolean,
  },
  components: {
    PageHeader,
    Spinner,
    Panel,
    EditProductDetails,
    AddEditTradePrice,
    Images,
    AddLineItem,
    EditLineItem,
    ConfirmAlert,
    FAQTable,
    ServiceTemplate,
    ServiceCertificate
  },
  data() {
    return {
      viewState: "Idle",
      currentTab: "pricing",
      errorMsg: null,
      product: null,
      tradePriceTitle: "",
      showingAddTradePricePanel: false,
      showingAddLineItemPanel: false,
      showingEditLineItemPanel: false,
      editDetailsPanelOpen: false,
      supplierNameSelectOpen: false,
      editingTradePrice: {},
      productDetails: {},
      lineItemToEdit: {},
      supplierSearchTerm: "",
      allSuppliers: [],
      supplierResults: [],
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
    };
  },
  methods: {
    createJobSheet() {
      if (this.productCode) {
        this.$router.push({
          name: "engineer-job-sheet-create",
          query: {
            product_code: this.productCode
          },
        });
      }
    },
    async loadFaqs(pageOrderSettings) {
      try {
        return await this.faqService.getFAQsByProductCode(
            pageOrderSettings,
            this.productCode
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    addNewTradePrice: function () {
      this.tradePriceTitle = "Add Trade Price";
      this.editingTradePrice = {};
      this.showingAddTradePricePanel = true;
    },
    saveTradePrice: async function (tradePrice) {
      this.viewState = "Loading";
      this.showingAddTradePricePanel = false;
      let existingBand = this.$_.find(
          this.product.price_bands,
          (band) => band.band_code == tradePrice.band_code
      );
      try {
        await this.ProductService.createUpdatePriceBand(
            this.product.product_code,
            tradePrice
        );
        if (existingBand) {
          existingBand.price = tradePrice.price;
          existingBand.delivery_out = tradePrice.delivery_out;
        } else {
          this.product.price_bands.push(tradePrice);
        }
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: existingBand
                          ? "Updated price band"
                          : "Created price band",
                      close: onClose,
                    },
                  },
                  existingBand
                      ? "Successfully updated the product price band."
                      : "Successfully created the product price band."
              );
            },
            {
              position: "top-right",
            }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: existingBand
                          ? "Failed to update price band"
                          : "Failed to create price band",
                      close: onClose,
                    },
                  },
                  existingBand
                      ? "There has been an error while trying to update the price band."
                      : "There has been an error while trying to create the price band"
              );
            },
            {
              position: "top-right",
            }
        );
      }
      this.viewState = "Idle";
    },
    editPriceBand: function (band) {
      this.tradePriceTitle = "Update Trade Price";
      this.editingTradePrice = {
        ...band,
      };
      this.showingAddTradePricePanel = true;
    },
    removePriceBand: async function (band) {
      try {
        await this.ProductService.deletePriceBand(
            this.product.product_code,
            band.band_code
        );
        this.$_.remove(
            this.product.price_bands,
            (priceBand) => priceBand.band_code == band.band_code
        );
        this.$forceUpdate();
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Deleted price band",
                      close: onClose,
                    },
                  },
                  "Successfully deleted price band."
              );
            },
            {
              position: "top-right",
            }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Failed to delete price band",
                      close: onClose,
                    },
                  },
                  "There has been an error while trying to delete price band"
              );
            },
            {
              position: "top-right",
            }
        );
      }
    },
    openEditPanel: function () {
      this.productDetails.name = this.product.name;
      this.productDetails.description = this.product.description;
      this.productDetails.category = this.product.category;
      this.productDetails.type = this.product.type;
      this.editDetailsPanelOpen = true;
    },
    updateProductDetails: async function (productDetails) {
      this.viewState = "Loading";
      try {
        let result = await this.ProductService.updateProduct(
            this.product.product_code,
            productDetails
        );
        this.product.name = productDetails.name;
        this.product.description = productDetails.description;
        this.product.category = productDetails.category;
        this.product.type = productDetails.type;
        this.editDetailsPanelOpen = false;
        this.productDetails = {};
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Details Updated",
                      close: onClose,
                    },
                  },
                  "Successfully updated the product details."
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Failed to update",
                      close: onClose,
                    },
                  },
                  "There has been an error while trying to update product details"
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      }
    },
    openSearchSupplier: function () {
      this.supplierSearchTerm = "";
      this.supplierResults = [];
      this.supplierNameSelectOpen = true;
      this.$nextTick(function () {
        this.$refs.tbSupplierSearch.focus();
      });
    },
    searchSupplier: _.debounce(function () {
      this.supplierResults = [];
      if (
          this.supplierSearchTerm != "" &&
          this.supplierSearchTerm.length >= 3
      ) {
        this.allSuppliers.forEach((s) => {
          if (
              s.name.toLowerCase().includes(this.supplierSearchTerm.toLowerCase())
          ) {
            this.supplierResults.push(s);
          }
        });
      }
    }, 500),
    selectSupplier: function (supplier) {
      this.product.supplier_info.company_id = supplier.supplier_id;
      this.product.supplier_info.name = supplier.name;
      this.supplierNameSelectOpen = false;
    },
    clearSupplier: function () {
      this.product.supplier_info = {
        company_id: "",
        name: "",
      };
      this.$forceUpdate();
    },
    addLineItem: async function (lineItem) {
      this.showingAddLineItemPanel = false;
      this.viewState = "Loading";
      try {
        let result = await this.ProductService.addUpdateLineItem(
            this.product.product_code,
            lineItem
        );
        this.product.line_items.push(lineItem);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Line Item Added",
                      close: onClose,
                    },
                  },
                  "Successfully added the line item"
              );
            },
            {
              position: "top-right",
              duration: 2000,
            }
        );
        this.viewState = "Idle";
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Failed to Add",
                      close: onClose,
                    },
                  },
                  "There has been an error while trying to add this line item"
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      }
    },
    updateLineItem: async function () {
      this.viewState = "Loading";
      this.showingEditLineItemPanel = false;
      try {
        let item = this.$_.find(
            this.product.line_items,
            (item) => item.product_code == this.lineItemToEdit.product_code
        );

        item.qty = this.lineItemToEdit.qty;
        let result = await this.ProductService.addUpdateLineItem(
            this.product.product_code,
            item
        );
        this.lineItemToEdit = "";
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Line Item Updated",
                      close: onClose,
                    },
                  },
                  "Successfully updated the line item"
              );
            },
            {
              position: "top-right",
              duration: 2000,
            }
        );
        this.viewState = "Idle";
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Failed to Update",
                      close: onClose,
                    },
                  },
                  "There has been an error while trying to update this line item"
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      }
    },
    removeLineItem: async function (lineItem) {
      this.viewState = "Loading";
      try {
        let result = await this.ProductService.deleteLineItem(
            this.product.product_code,
            lineItem
        );

        this.$_.remove(this.product.line_items, function (i) {
          return i == lineItem;
        });
        this.$forceUpdate();
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Line Item Deleted",
                      close: onClose,
                    },
                  },
                  "Successfully deleted the line item"
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Failed to Delete",
                      close: onClose,
                    },
                  },
                  "There has been an error while trying to delete this line item"
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      }
    },
    editLineItem: function (lineItem) {
      this.lineItemToEdit = {...lineItem};
      this.showingEditLineItemPanel = true;
    },
    handleArchiveProduct: async function (result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.ProductService.archiveProduct(this.product.product_code);
          this.$router.push({
            name: "product-index",
          });
          this.$breadstick.notify(
              ({h, onClose}) => {
                return h(
                    Notification,
                    {
                      props: {
                        title: "Archived Successfully",
                        close: onClose,
                      },
                    },
                    `The product '${this.product.product_code}' was archived successfully`
                );
              },
              {
                position: "top-right",
              }
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
              ({h, onClose}) => {
                return h(
                    Notification,
                    {
                      props: {
                        type: "danger",
                        title: "Error",
                        close: onClose,
                      },
                    },
                    "Error occurred while archiving this product, please try again later"
                );
              },
              {
                position: "top-right",
              }
          );
        }
        this.isBusy = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    archiveProduct: function () {
      this.confirmAlert = {
        resultFunction: this.handleArchiveProduct,
        message: `Are you sure you wish to archive this product?`,
        showing: true,
        data: this.product,
      };
    },
    saveProduct: async function () {
      this.viewState = "Loading";

      try {
        let result = await this.ProductService.updateProduct(
            this.product.product_code,
            this.product
        );

        this.viewState = "Idle";
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Product Updated",
                      close: onClose,
                    },
                  },
                  "This product has successfully been updated"
              );
            },
            {
              position: "top-right",
            }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Failed to Save",
                      close: onClose,
                    },
                  },
                  "There has been an error while trying to save changes on this product"
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      }
    },
    verifyPrice: async function () {
      this.viewState = "Loading";

      try {
        let result = await this.ProductService.verifyProductPrice(
            this.product.product_code
        );
        this.viewState = "Idle";
        this.product.verified_at = new Date();
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Price Verified",
                      close: onClose,
                    },
                  },
                  "This price of this product has been verified successfully"
              );
            },
            {
              position: "top-right",
            }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Error",
                      close: onClose,
                    },
                  },
                  "There has been an error while trying to verify the price of this product"
              );
            },
            {
              position: "top-right",
            }
        );
        this.viewState = "Idle";
      }
    },
    generateVerifiedButton: function () {
      if (this.product) {
        if (!this.product.verified_at) {
          return `
          <button
            type="button"
            class="bg-red-200 hover:bg-red-300 border-red-400 hover:border-red-500 flex items-center border hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
          >
            <svg
              class="stroke-current w-5 h-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path
                d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0zM12 9v4M12 17h.01"
              />
            </svg>
            <span>Verify</span>
          </button>
        `;
        } else if (
            this.$moment(this.product.verified_at).isAfter(
                this.$moment().subtract(12, "months")
            )
        ) {
          return `
          <button
            type="button"
            class="bg-green-200 hover:bg-green-300 border-green-400 hover:border-green-500 flex items-center border hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
          >
            <svg
              class="stroke-current w-5 h-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
              <path d="M22 4L12 14.01l-3-3" />
            </svg>
            <span>Verified</span>
          </button>
        `;
        } else if (
            this.$moment(this.product.verified_at).isAfter(
                this.$moment().subtract(18, "months")
            )
        ) {
          return `
          <button
            type="button"
            class="bg-orange-200 hover:bg-orange-300 border-orange-400 hover:border-orange-500 flex items-center border hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
          >
            <svg
              class="stroke-current w-5 h-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M12 16v-4M12 8h.01" />
            </svg>
            <span>Verify</span>
          </button>
        `;
        } else if (
            this.$moment(this.product.verified_at).isAfter(
                this.$moment().subtract(24, "months")
            )
        ) {
          return `
          <button
            type="button"
            class="bg-red-200 hover:bg-red-300 border-red-400 hover:border-red-500 flex items-center border hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
          >
            <svg
              class="stroke-current w-5 h-5 mr-2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
            >
              <path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0zM12 9v4M12 17h.01" />
            </svg>
            <span>Verify</span>
          </button>
        `;
        }
      }
    },
  },
  async mounted() {
    this.viewState = "Loading";

    this.allSuppliers = await this.ProductService.getSuppliers();

    try {
      this.product = await this.ProductService.getProductByCodeV1(
          this.productCode
      );
      if (!this.product.location) {
        this.product.location = {};
      }
      if (!this.product.supplier_info) {
        this.product.supplier_info = {};
      }

      if (this.isCreateResult) {
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Product Created",
                      close: onClose,
                    },
                  },
                  "This product has successfully been created"
              );
            },
            {
              position: "top-right",
            }
        );
      }
      this.viewState = "Idle";
    } catch (error) {
      if (error.status === 404) {
        this.errorMsg = `Cannot find Product with code ${this.productCode}`;
      }
      console.error(error);
      this.viewState = "Error";
    }
  },
};
</script>
