<template>
    <div>
        <DataTable :value="faqs" :lazy="true" :paginator="true" :rows="10" ref="dt" :totalRecords="totalRecords" stripedRows
            :loading="loading" @page="onPage($event)" @sort="onSort($event)" sortField="created_at" :sortOrder="-1"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 20, 50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            selectionMode="single" @row-select="onRowSelect">
            <Column field="faq_number" header="FAQ Id" sortable :headerStyle="{ width: '10%'}">></Column>
            <Column field="title" header="FAQ Title" sortable :headerStyle="{ width: '25%'}"></Column>
            <Column header="Visibility" sortable :headerStyle="{ width: '20%'}">
                <template #body="slotProps">
                    <span v-for="(part, index) in splitVisibility(slotProps.data.visibility)">
                        <span class="expiry-badge status-normal mr-1">
                            {{ part }}
                        </span>
                    </span>
                </template>
            </Column>
            <Column field="category" header="Product Category" sortable :headerStyle="{ width: '20%'}"></Column>
            <Column field="created_at" header="Created" sortable :headerStyle="{ width: '15%'}">
                <template #body="slotProps">
                    <span>
                        {{ formatCreatedAt(slotProps.data.created_at) }}
                    </span>
                </template>
            </Column>
            <Column v-if="!this.compactVersion" :headerStyle="{ width: '10%'}">
                <template #body="slotProps">
                    <div class="flex space-x-2">
                        <is-authorized :permissions="['manage:fleets', 'read:fleets', 'update:fleets']">
                            <button @click="editFaq(slotProps.data, $event)"
                                class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                                name="Edit Published Doc">
                                <svg class="stroke-current h-5 w-5" fill="none" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                                    <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                </svg>
                            </button>
                        </is-authorized>
                        <is-authorized :permissions="['manage:fleets', 'read:fleets', 'update:fleets']">
                            <button v-if="compactVersion === false" type="button" @click="deleteFaq(slotProps.data, $event)"
                                class="flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1">
                                <svg class="stroke-current h-5 w-5" fill="none" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" viewBox="0 0 24 24">
                                    <path
                                        d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                                </svg>
                            </button>
                        </is-authorized>
                    </div>
                </template>
            </Column>
          <template #empty>
            No records found.
          </template>
        </DataTable>

        <portal to="overlay-outlet">
            <Panel :showing="computedFaqPanelOpen" @close="showingCreateFaqPanel = false" :title="CreateFaqTitle">
                <CreateFaq @complete="faqCreated" :faqId="selectedFaq.faq_id" :isEdit="isFaqEdit" />
            </Panel>
        </portal>
        <portal to="overlay-outlet">
            <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message"
                @complete="confirmAlert.resultFunction" />
        </portal>
    </div>
</template>
  
<script>

const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");
const Panel = () => import("@/components/Panel.vue");
const CreateFaq = () => import("@/components/Faqs/CreateFaq.vue");
import ConfirmAlert from "@/components/ConfirmAlert.vue";

export default {
    components: {
        IsAuthorized,
        Panel,
        CreateFaq,
        ConfirmAlert
    },
    props: {
        loadFaqs: Function,
        compactVersion: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        computedFaqPanelOpen() {
            return this.showingCreateFaqPanel;
        },
    },
    data() {
        return {
            showingCreateFaqPanel: false,
            CreateFaqTitle: '',
            selectedFaq: {},
            isFaqEdit: false,
            data: [],
            totalRecords: 0,
            loading: false,
            lazyParams: {},
            faqs: [],
            confirmAlert: {
                resultFunction: "",
                showing: false,
                message: "",
                title: "",
                data: "",
            }
        }
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: "created_at",
            sortOrder: "desc",
            page: 0
        };

        this.loadLazyData();
    },
    created() {
        this.$parent.$on('open-faq-panel', this.openFaqPanel);
        this.$parent.$on('load-faq', this.loadLazyData);
    },
    beforeDestroy() {
        this.$parent.$off('open-faq-panel', this.openFaqPanel);
        this.$parent.$off('load-faq', this.loadLazyData);
    },
    methods: {
        openFaqPanel() {
            this.selectedFaq = {};
            this.isFaqEdit = false;
            this.CreateFaqTitle = 'Create FAQ';
            this.showingCreateFaqPanel = true;
            this.$emit('panel-opened');
        },
        downloadFAQ(row, event) {
            event.stopImmediatePropagation();
        },
        editFaq(row, event) {
            this.CreateFaqTitle = `Edit FAQ - ${row.faq_number}`;
            this.selectedFaq = { ...row };
            this.isFaqEdit = true;
            this.showingCreateFaqPanel = true;
            event.stopImmediatePropagation();
        },
        deleteFaq(row, event) {
            this.confirmAlert = {
                resultFunction: this.deleteFaqById,
                message:
                    "Are you sure you want to delete this FAQ? Cannot be undone",
                showing: true,
                data: row,
            };
            event.stopImmediatePropagation();
        },
        deleteFaqById: async function (result) {
            if (result) {
                this.viewState = "Loading";
                try {
                    await this.faqService.deleteFaqById(
                        this.confirmAlert.data.faq_id
                    );
                    await this.loadLazyData();
                } catch (error) {
                    console.error(error);
                } finally {
                    this.viewState = "Idle";
                }
            }
            this.confirmAlert = {
                resultFunction: "",
                message: "",
                showing: false,
                data: "",
            };
        },
        splitVisibility(visibility) {
            return visibility.split('/').map((value) => value.trim());
        },
        formatCreatedAt(created_at) {
            return created_at == 0 ? "" : this.$moment.unix(created_at).format("DD/MM/YYYY");
        },
        faqCreated: async function () {
            await this.loadLazyData();
            this.showingCreateFaqPanel = false;
        },
        onPage(event) {
            const pageOption = { page: event.page, pageCount: event.pageCount };
            this.lazyParams = { ...this.lazyParams, ...pageOption };
            this.loadLazyData();
        },
        onSort(event) {
            const sortOption = { sortField: event.sortField, sortOrder: event.sortOrder };
            this.lazyParams = { ...this.lazyParams, ...sortOption };
            this.loadLazyData();
        },
        async loadLazyData() {
            this.loading = true;
            try {
                let result = await this.loadFaqs({
                    page: this.lazyParams.page + 1,
                    limit: this.lazyParams.rows,
                    orderBy: this.lazyParams.sortField || 'created_at',
                    order: this.lazyParams.sortOrder == 1 ? "asc" : "desc"
                });
                if (result) {
                    this.faqs = result.data || [];
                    this.totalRecords = result.count || 0;
                }
                this.loading = false;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        onRowSelect(event) {
            // if (!this.compactVersion) {
                let faqId = event.data.faq_id;
                this.$router.push({
                    name: "faq-edit",
                    params: { faqId: faqId },
                });
            // }
        }
    }
}
</script>