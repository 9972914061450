<template>
  <div class="w-full bg-white mt-6 p-5 pt-1 rounded  relative">

    <div class="flex items-center mb-5">
      <h3
          class="uppercase text-lg font-semibold text-primary tracking-wide"
      >
        Manage Product Certificates
      </h3>
      <hr class="flex-1 border-primary ml-4"/>
    </div>

    <!-- Certificate List -->
    <div>
      <Accordion class="accordion-custom">
        <AccordionTab
            v-for="certificate in displayCertificates"
            :key="certificate.data_master_id"
            @tab-open="handleTabOpen(certificate.data_master_id)"
            @tab-close="handleTabClose(certificate.data_master_id)">
          <template #header>
            <span>{{ certificate.name }}</span>
            <span v-if="selectedCertificates.includes(certificate.data_master_id)"
                  class="ml-2 text-green-500 font-bold uppercase">Selected</span>
          </template>
          <div class="flex items-center">
            <Checkbox
                v-model="selectedCertificates"
                :value="certificate.data_master_id"
                @change="handleSelectionChange(certificate.data_master_id)"/>
            <label :for="certificate.data_master_id" class="ml-2">{{ certificate.name }}</label>
          </div>
          <TabView v-if="selectedCertificates.includes(certificate.data_master_id)" class="px-10 py-5">
            <TabPanel header="Statement" class="px-10 py-5">
              <Editor rows="3" cols="10" :options="editorOptions"
                      v-model="certificate.description"/>
            </TabPanel>
            <TabPanel header="Service List" class="px-10 py-5">
              <ServiceManager :service_checks="certificate.service_checks"/>
            </TabPanel>
          </TabView>
        </AccordionTab>
      </Accordion>
    </div>

    <!-- Save Button -->
    <div class="flex justify-end mt-4">
      <AppButton color="blue" label="Save Certificates" @click="saveCertificates" :icon=true>
        <template v-slot:icon>
          <Icon iconType="save" class="mr-2"/>
        </template>
      </AppButton>
    </div>
  </div>
</template>

<script>

import AppButton from '@/components/Button.vue';
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import ServiceManager from './ServiceManager.vue';

export default {
  mixins: [notificationMixin],
  props: {
    product_id: {
      type: String,
      required: true
    },
    productCertificates: {
      type: Array,
      required: false,
      default: () => [] // Use factory function for default value
    }
  },
  components: {
    AppButton,
    Icon,
    ServiceManager,
  },
  data() {
    return {
      dataMasterCertificates: [],
      selectedCertificates: [],
      displayCertificates: [],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        },
        theme: 'snow'
      },
      openedCertificates: new Set(),
    };
  },
  async mounted() {
    await this.fetchDataMasterCertificates();
    this.selectedCertificates = this.productCertificates.map(item => item.data_master_id);
    this.initializeDisplayCertificates()
  },
  watch: {
    selectedCertificates(newVal) {
      newVal.forEach(id => {
        const productCert = this.productCertificates.find(cert => cert.data_master_id === id);
        const displayCert = this.displayCertificates.find(cert => cert.data_master_id === id);
        if (productCert && displayCert) {
          this.$set(displayCert, 'description', productCert.description);
        }
      });
    }
  },
  methods: {

    headerLabel(certificate) {
      // Updates the header label to include a tick mark if the tab is opened
      return this.openedCertificates.has(certificate.data_master_id) ? `✔️ ${certificate.name}` : certificate.name;
    },

    handleTabOpen(data_master_id) {
      // Adds the ID to the openedCertificates set when a tab is opened
      this.openedCertificates.add(data_master_id);
    },
    handleTabClose(data_master_id) {
      // Removes the ID from the openedCertificates set when a tab is closed
      this.openedCertificates.delete(data_master_id);
    },
    addService(data_master_id) {
      // Logic to add a service to the certificate
    },
    saveService(service) {
      // Logic to save edits to a service
    },
    deleteService(serviceId) {
      // Logic to delete a service
    },
    initializeDisplayCertificates() {
      this.displayCertificates = this.dataMasterCertificates.map(masterCert => {
        const productCert = this.productCertificates.find(cert => cert.data_master_id === masterCert.data_master_id);
        return {
          ...masterCert,
          description: productCert ? productCert.description : masterCert.description,
          service_checks: productCert ? productCert.service_checks : masterCert.service_checks,
        };
      });
    },
    handleSelectionChange(dataMasterId) {
      if (!this.selectedCertificates.includes(dataMasterId)) {
        // Checkbox is unchecked
        const masterCert = this.dataMasterCertificates.find(cert => cert.data_master_id === dataMasterId);
        const displayCert = this.displayCertificates.find(cert => cert.data_master_id === dataMasterId);
        if (masterCert && displayCert) {
          this.$set(displayCert, 'description', masterCert.description);
        }
      } else {
        // Checkbox is checked
        const productCert = this.productCertificates.find(cert => cert.data_master_id === dataMasterId);
        const displayCert = this.displayCertificates.find(cert => cert.data_master_id === dataMasterId);
        if (productCert && displayCert) {
          this.$set(displayCert, 'description', productCert.description);
        }
      }
    },

    async fetchDataMasterCertificates() {
      try {
        const response = await this.DatamasterService.getDataMasters('?noPaging=true&type=certificate&is_active=true&orderBy=name&order=1');
        this.dataMasterCertificates = response.map(item => ({
          data_master_id: item.data_master_id,
          name: item.name,
          description: item.description || '',
        }));
      } catch (error) {
        console.error('Error fetching data master certificates:', error);
      }
    },

    async saveCertificates() {

      const selectedCerts = this.selectedCertificates.map(id => {
        const certificate = this.displayCertificates.find(cert => cert.data_master_id === id);

        const validServiceChecks = Array.isArray(certificate.service_checks) ? certificate.service_checks.filter(serviceCheck =>
            serviceCheck.question_text && serviceCheck.question_text.trim() !== ''
        ) : [];

        const certificateData = {
          data_master_id: certificate.data_master_id,
          name: certificate.name,
          description: certificate.description,
        };

        if (validServiceChecks.length > 0) {
          certificateData.service_checks = validServiceChecks;
        }

        return certificateData;
      });


      const confirmation = await this.confirmAction({
        message: 'Do you want to save changes to the certificates?',
        header: 'Confirm Certificate Update',
        acceptLabel: 'Continue',
        rejectLabel: 'Cancel',
      });

      if (!confirmation) {
        return;
      }

      try {
        await this.ProductService.bulkUpdateCertificateTypes(this.product_id, selectedCerts);
        this.notifySuccess("Certificate(s) updated successfully");
      } catch (error) {
        this.notifyError("An error occurred while updating certificates.");
      }
    },

  }
};
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

.custom-datatable .p-datatable-tbody > tr:last-child td {
  border-bottom: none;
}
</style>
