<template>
  <div class="card">

    <DataTable :value="localNotes" dataKey="id" class="hide-header custom-datatable">
      <Column field="text" :styles="{ width: '80%' }">
        <template #body="slotProps">
          <Textarea rows="3" cols="10" v-if="editableRowId === slotProps.data.template_id"
                    v-model="slotProps.data.description"/>
          <span v-else>{{ slotProps.data.description }}</span>
        </template>
      </Column>

      <Column :styles="{ width: '20%' }">
        <template #body="slotProps">
          <div class="flex justify-end items-center">
            <AppButton v-if="editableRowId === slotProps.data.template_id" color="blue" label=""
                       :icon=true
                       class="mr-1"
                       @click="saveRow(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="save"/>
              </template>
            </AppButton>
            <AppButton v-if="editableRowId === slotProps.data.template_id && localNotes.length !== 0" color="gray"
                       label=""
                       :icon=true
                       class="mr-1"
                       @click="cancelEdit(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="close"/>
              </template>
            </AppButton>
            <AppButton v-else color="blue" label=""
                       :icon=true
                       class="mr-1"
                       @click="editRow(slotProps.data.template_id)">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
            <AppButton color="red" label=""
                       :icon=true
                       class="mr-1"
                       @click="deleteRow(slotProps.data.template_id)">
              <template v-slot:icon>
                <Icon iconType="delete"/>
              </template>
            </AppButton>
            <AppButton color="green" v-if="isLastRow(slotProps.data.template_id)" label=""
                       :icon=true
                       class="mr-1"
                       @click="addNote">
              <template v-slot:icon>
                <Icon iconType="add-notes"/>
              </template>
            </AppButton>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import {string} from "../../../../public/ViewerJS/webodf";

export default {
  mixins: [notificationMixin],
  components: {Icon, AppButton},
  props: {
    product_id: {
      type: string,
      required: false,

    },
  },
  data() {
    return {
      localNotes: [],
      editableRowId: null,
    };
  },

  async mounted() {
    await this.fetchNotes();
    this.checkAndAddInitialNote();
  },

  methods: {
    checkAndAddInitialNote() {

      if (this.localNotes.length === 0) {
        this.addNote();
      }
    },
    async fetchNotes() {
      try {
        const response = await this.ProductService.getProductById(this.product_id);
        this.localNotes = response.service_template;
      } catch (error) {
        console.error("Failed to fetch notes:", error);
        this.notifyError("Failed to load notes.");
      }
    },
    async addNote() {
      if (this.editableRowId != null) {
        this.notifyWarning("Please save the current note before adding a new one.");
        return;
      }
      try {
        const newNote = {template_id: this.generateGUID(), text: '', isNew: true};
        this.localNotes.push(newNote);
        this.editableRowId = newNote.template_id;
      } catch (error) {
        console.error("Failed to add note:", error);
        this.notifyError("Failed to add note.");
      }
    },
    async saveRow(note) {
      if (!note.description.trim()) {
        this.notifyError("The note text cannot be empty.");
        return;
      }

      try {
        if (note.isNew) {
          await this.ProductService.createProductServiceTemplate(this.product_id, note);
        } else {
          await this.ProductService.updateProductServiceTemplate(this.product_id, note.template_id, note);
        }
        await this.fetchNotes();
        this.editableRowId = null;
      } catch (error) {
        console.error("Failed to save note:", error);
        this.notifyError("Failed to save note.");
      }
    },
    async deleteRow(rowId) {
      const isConfirmed = await this.confirmAction('Are you sure you want to delete this item?');
      if (!isConfirmed) {
        return;
      }

      try {

        await this.ProductService.deleteServiceTemplate(this.product_id, rowId);
        await this.fetchNotes();
      } catch (error) {
        console.error("Failed to delete note:", error);
        this.notifyError("Failed to delete note.");
      }
      this.editableRowId = null;
    },
    cancelEdit(note) {
      if (note.isNew) {
        this.localNotes = this.localNotes.filter(n => n.template_id !== note.template_id);
      }
      this.editableRowId = null;

      this.$nextTick(() => {
        this.checkAndAddInitialNote();
      });
    },
    editRow(rowId) {
      if (this.editableRowId !== null) {
        const currentIndex = this.localNotes.findIndex(note => note.template_id === this.editableRowId);
        if (currentIndex !== -1 && !this.localNotes[currentIndex].description.trim()) {
          this.localNotes.splice(currentIndex, 1);
        }
      }
      this.editableRowId = rowId;
    },
    isLastRow(rowId) {
      return this.localNotes[this.localNotes.length - 1].template_id === rowId;
    },
    generateGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
  },
  watch: {
    initialNotes: {
      immediate: true,
      handler(newNotes) {
        this.localNotes = newNotes;
        this.editableRowId = null;
      },
    },
  },
};
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}

.custom-datatable .p-datatable-tbody > tr:last-child td {
  border-bottom: none;
}
</style>
