<template>
  <div class="w-full h-5 border border-primary rounded-lg overflow-hidden">
      <div class="inline-block h-full bg-orange-300 max-w-full" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      required: true,
      type: Number
    }
  },
};
</script>