<template>
  <svg
      class="stroke-current h-5 w-5 "
      fill="none"
      :stroke-width="iconData.strokeWidth || defaultStrokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      viewBox="0 0 24 24"
  >
    <circle
        v-for="(circle, index) in iconData.circles"
        :key="'circle-' + index"
        :cx="circle.cx"
        :cy="circle.cy"
        :r="circle.r"
        :stroke="circle.stroke || iconData.defaultStroke || defaultStroke"
    />
    <path
        v-for="(path, index) in iconData.paths"
        :key="'path-' + index"
        :d="path.d"
        :stroke="path.stroke || iconData.defaultStroke || defaultStroke"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaultStrokeWidth: 2,
      defaultStroke: "currentColor",
      icons: {
        confirmed: {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none"},
            {d: "M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"},
            {d: "M9 12l2 2l4 -4"},
          ],
        },
        "not-confirmed": {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none"},
            {d: "M10 20.777a8.942 8.942 0 0 1-2.48-.969"},
            {d: "M14 3.223a9.003 9.003 0 0 1 0 17.554"},
            {d: "M4.579 17.093a8.961 8.961 0 0 1-1.227-2.592"},
            {d: "M3.124 10.5c.16-.95.468-1.85.9-2.675l.169-.305"},
            {d: "M6.907 4.579a8.954 8.954 0 0 1 3.093-1.356"},
            {d: "M9 12l2 2l4 -4"},
          ],
        },
        search: {
          circles: [{cx: "11", cy: "11", r: "8"}],
          paths: [{d: "M21 21l-4.35-4.35"}],
        },
        delete: {
          circles: [],
          paths: [
            {
              d: "M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6",
            },
          ],
        },
        save: {
          circles: [],
          paths: [
            {
              d: "M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
            },
            {
              d: "M17 21v-8H7v8M7 3v5h8"
            }
          ]
        },
        edit: {
          circles: [],
          paths: [
            {d: "M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"},
          ],
        },
        checkbox: {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none"},
            {d: "M9 11l3 3l8 -8"},
            {
              d: "M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9",
            },
          ],
        },
        upload: {
          circles: [],
          paths: [
            {
              d: "M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12"
            },
          ],
        },
        add: {
          circles: [],
          paths: [
            {
              d: "M12 5v14M5 12h14"
            },
          ],
        },
        close: {
          circles: [],
          paths: [
            {
              d: "M18 6 6 18M6 6l12 12"
            },
          ],
        },
        print: {
          circles: [],
          paths: [
            {
              d: "M6 9V2h12v7M6 18H4a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2"
            },
            {
              d: "M6 14h12v8H6z"
            }
          ]
        },
        "add-notes": {
          circles: [],
          paths: [
            {d: "M19 10h-14"},
            {d: "M5 6h14"},
            {d: "M14 14h-9"},
            {d: "M5 18h6"},
            {d: "M18 15v6"},
            {d: "M15 18h6"}
          ]
        },
        "transition-right": {
          circles: [],
          paths: [
            {d: "M18 3a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3"},
            {d: "M3 18v-12a3 3 0 1 1 6 0v12a3 3 0 0 1 -6 0z"},
            {d: "M9 12h8"},
            {d: "M14 15l3 -3l-3 -3"}
          ]
        },
        "copy-plus": {
          circles: [],
          paths: [
            {
              d: "M7 9.667A2.667 2.667 0 0 1 9.667 7h8.666A2.667 2.667 0 0 1 21 9.667v8.666A2.667 2.667 0 0 1 18.333 21H9.667A2.667 2.667 0 0 1 7 18.333z" // Second <path>
            },
            {
              d: "M4.012 16.737A2.005 2.005 0 0 1 3 15V5c0-1.1.9-2 2-2h10c.75 0 1.158.385 1.5 1M11 14h6M14 11v6" // Third <path>
            }
          ]
        },
        "clipboard-text": {
          circles: [],
          paths: [
            {
              d: "M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2"
            },
            {
              d: "M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v0a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2z"
            },
            {
              d: "M9 12h6"
            },
            {
              d: "M9 16h6"
            }
          ],
        },

        "file-import": {
          "circles": [],
          "paths": [
            {
              "d": "M14 3v4a1 1 0 0 0 1 1h4"
            },
            {
              "d": "M5 13V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2h-5.5"
            },
            {
              "d": "M2 19h7m-3-3 3 3-3 3"
            }
          ]
        },
        "certificate-icon": {
          "circles": [],
          "paths": [
            {"d": "M0 0h24v24H0z"},
            {"d": "M12 15a3 3 0 1 0 6 0 3 3 0 1 0-6 0"},
            {"d": "M13 17.5V22l2-1.5 2 1.5v-4.5"},
            {"d": "M10 19H5a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-1 1.73"},
            {"d": "M6 9h12"},
            {"d": "M6 12h3"},
            {"d": "M6 15h2"}
          ]
        },
        "edit-quotation": {
          "circles": [],
          "paths": [
            {"d": "M0 0h24v24H0z", "stroke": "none"},
            {"d": "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"},
            {"d": "M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1-4 0v-4a2 2 0 0 1 2-2zM13 15l1 1"}
          ]
        },
        "edit-job-sheet": {
          "circles": [],
          "paths": [
            {"d": "M0 0h24v24H0z", "stroke": "none"},
            {"d": "M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"},
            {"d": "M10 8h4v6a2 2 0 1 1-4 0"}
          ]
        },
        mail: {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none"},
            {d: "M3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7z"},
            {d: "m3 7 9 6 9-6"},
          ],
        },
        "thumb-up": {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none"},
            {d: "M7 11v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h3a4 4 0 0 0 4-4V6a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1-2 2h-7a3 3 0 0 1-3-3"},
          ],
        },
        "package-import": {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none"},
            {d: "M12 21l-8-4.5v-9L12 3l8 4.5V12"},
            {d: "M12 12l8-4.5"},
            {d: "M12 12v9"},
            {d: "M12 12L4 7.5"},
            {d: "M22 18h-7"},
            {d: "M18 15l-3 3 3 3"},
          ],
        },
        "circle-minus": {
          circles: [
            { cx: "12", cy: "12", r: "9" },
          ],
          paths: [
            { d: "M0 0h24v24H0z", stroke: "none" },
            { d: "M9 12h6" },

          ],
        },
        "circle-plus": {
          circles: [
            {cx: "12", cy: "12", r: "9"},
          ],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none"},
            {d: "M3 12a9 9 0 1 0 18 0 9 9 0 0 0-18 0M9 12h6M12 9v6"},
          ],
        },

        "truck-delivery": {
          circles: [],
          paths: [
            { d: "M0 0h24v24H0z", stroke: "none" },
            { d: "M5 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0M15 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0" },
            { d: "M5 17H3v-4M2 5h11v12m-4 0h6m4 0h2v-6h-8m0-5h5l3 5M3 9h4" },
          ],
          strokeWidth:2,
        },

        "list-check": {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none", fill: "none"},
            {d: "M3.5 5.5l1.5 1.5l2.5 -2.5"},
            {d: "M3.5 11.5l1.5 1.5l2.5 -2.5"},
            {d: "M3.5 17.5l1.5 1.5l2.5 -2.5"},
            {d: "M11 6l9 0"},
            {d: "M11 12l9 0"},
            {d: "M11 18l9 0"}
          ],
        },
        "table-list": {
          circles: [],
          paths: [
            {d: "M0 0h24v24H0z", stroke: "none", fill: "none"},
            {d: "M9 6h11M9 12h11M9 18h11M5 6v.01M5 12v.01M5 18v.01"},

          ],
        },

      },
    };
  },
  computed: {
    iconData() {
      return this.icons[this.iconType] || {circles: [], paths: []};
    },
  },
};
</script>

