<template>
  <form @submit.prevent="saveProductDetails" class="flex flex-wrap py-4">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
        v-model="product.name"
        class="w-full bge-input bge-input-spacing rounded"
        type="text"
        required
        placeholder="Product Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Category</label>
      <div class="relative">
        <select
          v-model="product.category"
          class="bge-input bge-select rounded"
        >
          <option>Vehicle Lifts</option>
          <option>Brake Testing</option>
          <option>Emission Equipment</option>
          <option>Jacking Beams</option>
          <option>Headlamp Testing</option>
          <option>Diagnostics</option>
          <option>MOT Packages</option>
          <option>Spare Parts / Other</option>
          <option>Play Detectors</option>
          <option>Air Conditioning</option>
          <option>Software</option>
          <option>Wheel and Tyre</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Type</label>
      <div class="relative">
        <select
          v-model="product.type"
          class="bge-input bge-select rounded"
        >
          <option>Equipment with Serial Number</option>
          <option>Equipment without Serial Number</option>
          <option>Spares</option>
          <option>Accessories</option>
          <option>Consumables</option>
          <option>Package</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Description</label>
      <textarea
        v-model="product.description"
        placeholder="Product Description..."
        class="w-full bge-input bge-input-spacing rounded"
        rows="10"
      ></textarea>
    </div>
    <div class="w-full">
      <button
        type="button"
        @click="saveProductDetails"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Product Details</span>
      </button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    product: {
      required: true,
      type: Object
    }
  },
  methods: {
    saveProductDetails: function() {
      this.$emit("complete", this.product);
    }
  }
};
</script>