<template>
  <div class="card">
    <DataTable :value="service_checks" dataKey="id" class="hide-header custom-datatable">
      <Column field="text" :styles="{ width: '80%' }">
        <template #body="slotProps">
          <Textarea rows="3" cols="10" v-if="editableRowId === slotProps.data.question_id"
                    v-model="slotProps.data.question_text"/>
          <span v-else>{{ slotProps.data.question_text }}</span>
        </template>
      </Column>
      <Column :styles="{ width: '20%' }">
        <template #body="slotProps">
          <div class="flex justify-end items-center">
            <AppButton v-if="editableRowId === slotProps.data.question_id" color="blue" label=""
                       :icon=true class="mr-1" @click="saveService(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="save"/>
              </template>
            </AppButton>
            <AppButton v-if="editableRowId === slotProps.data.question_id && service_checks.length !== 0" color="gray"
                       label="" :icon=true class="mr-1" @click="cancelEdit(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="close"/>
              </template>
            </AppButton>
            <AppButton v-else color="blue" label=""
                       :icon=true class="mr-1" @click="editService(slotProps.data.question_id)">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
            <AppButton color="red" label=""
                       :icon=true class="mr-1" @click="deleteService(slotProps.data.question_id)">
              <template v-slot:icon>
                <Icon iconType="delete"/>
              </template>
            </AppButton>
            <AppButton color="green" v-if="isLastService(slotProps.data.question_id)" label=""
                       :icon=true class="mr-1" @click="addService">
              <template v-slot:icon>
                <Icon iconType="add-notes"/>
              </template>
            </AppButton>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {Icon, AppButton},
  props: {
    service_checks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editableRowId: null
    };
  },
  mounted() {
    this.checkAndAddInitialService();
  },
  methods: {
    checkAndAddInitialService() {
      if (this.service_checks.length === 0) {
        this.addService();
      }
    },
    addService() {
      if (this.editableRowId != null) {
        this.notifyWarning("Please save the current service check before adding a new one.");
        return;
      }
      const newServiceCheck = {
        question_id: this.generateGUID(),
        question_text: '',
        isNew: true
      };
      this.service_checks.push(newServiceCheck);
      this.editableRowId = newServiceCheck.question_id;
    },
    saveService(service) {
      if (!service.question_text.trim()) {
        this.notifyError("The service check text cannot be empty.");
        return;
      }
      service.isNew = false; // Mark the service check as no longer new
      this.editableRowId = null;
      this.notifySuccess("Service check saved successfully.");
    },
    async deleteService(serviceId) {

      const isConfirmed = await this.confirmAction('Are you sure you want to delete this item?');
      if (!isConfirmed) {
        return;
      }

      const index = this.service_checks.findIndex(s => s.question_id === serviceId);
      if (index !== -1) {
        this.service_checks.splice(index, 1);
        this.notifySuccess("Service check deleted successfully.");
      } else {
        this.notifyError("Failed to find service check to delete.");
      }
      this.editableRowId = null;
    },
    async cancelEdit() {
      if (this.editableRowId && this.service_checks.some(s => s.question_id === this.editableRowId && s.isNew)) {
        await this.deleteService(this.editableRowId);
      }
      this.editableRowId = null;
    },
    editService(serviceId) {
      this.editableRowId = serviceId;
    },
    isLastService(serviceId) {
      return this.service_checks[this.service_checks.length - 1].question_id === serviceId;
    },
    generateGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
};
</script>


<style>
.hide-header .p-datatable-thead {
  display: none;
}

.custom-datatable .p-datatable-tbody > tr:last-child td {
  border-bottom: none;
}
</style>
