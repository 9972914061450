<template>
  <div>
    <div class="flex flex-wrap -my-3 -mx-5">
      <div class="flex flex-col w-full my-3 px-5">
        <p
          class="text-center"
          v-if="images.length == 0"
        >No images have been added to this product yet</p>
        <div class="mb-5" v-if="isUploading">
          <!-- <p class="font-bold text-lg mb-2">Uploading image {{ image.name ? image.name : '' }}</p> -->
          <progress-bar :progress="uploadProgress" />
        </div>
        <div class="flex flex-wrap -my-2">
          <template v-for="(img, idx) in images">
            <div class="p-2 sm:w-1/2 md:w-1/3 lg:w-1/4" :key="idx">
              <div class="rounded overflow-hidden bg-gray-100 h-full flex flex-col">
                <div class="flex-1" v-if="img.file_status == 'Available'">
                  <img class="w-full h-full object-cover" :src="img.access_url" alt />
                </div>
                <div class="p-2">
                  <h2 class="font-semibold">{{ img.name ? img.name : img.original_filename }}</h2>
                  <p v-if="img.name" class="text-sm text-gray-600">{{ img.original_filename }}</p>
                  <p class="text-sm">{{ img.description }}</p>
                  <div class="flex justify-between mt-5">
                    <div class="flex">
                      <button
                        @click="editImage(img)"
                        title="Edit"
                        type="button"
                        class="bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                      >
                        <svg
                          class="stroke-current h-5 w-5"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />/>
                        </svg>
                      </button>
                      <button
                        @click="deleteImage(img)"
                        title="Delete"
                        type="button"
                        class="ml-2 bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                      >
                        <svg
                          class="stroke-current h-5 w-5"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                          />
                        </svg>
                      </button>
                    </div>
                    <!-- <div>
                      <button
                        title="Make Primary"
                        type="button"
                        class="bg-indigo-200 border hover:bg-indigo-300 border-indigo-400 hover:border-indigo-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                      >
                        <svg
                          class="stroke-current h-5 w-5"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="8" r="7" />
                          <path d="M8.21 13.89L7 23l5-3 5 3-1.21-9.12" />
                        </svg>
                      </button>
                      <span
                        class="inline-flex bg-yellow-200 border border-yellow-400 text-secondary rounded-md p-1"
                      >
                        <svg
                          title="Primary Image"
                          class="stroke-current h-5 w-5"
                          fill="none"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
                          />
                        </svg>
                      </span>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="py-4 flex items-center">
      <button
        @click="newImage"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12" />
        </svg>
        <span>Upload Images</span>
      </button>
      <hr class="flex-1 border-primary ml-4" />
    </div>

    <panel
      :showing="addEditImagePanelShowing"
      @close="addEditImagePanelShowing = false"
      title="Add Image"
    >
      <add-edit-image :images="editingImages" @complete="closeImagePanel" />
    </panel>

    <confirm-alert
      :showingConfirm="confirmAlert.showing"
      :message="confirmAlert.message"
      @complete="confirmAlert.resultFunction"
    />
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import AddEditImage from "@/components/Products/Edit/Images/AddEditImage.vue";

export default {
  name: "Images",
  components: {
    Panel,
    AddEditImage,
    Notification,
    ConfirmAlert,
    ProgressBar
  },
  props: {
    product: Object
  },
  data() {
    return {
      images: [],
      addEditImagePanelShowing: false,
      editingImages: {},
      uploadProgress: 0,
      isUploading: false,
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: ""
      }
    };
  },
  async mounted() {
    this.images = this.product.media;
    // try {
    //   let result = await this.ProductService.getProductAttachments(
    //     this.product.product_code
    //   );
    //   this.images = result.data;
    // } catch (err) {
    //   this.$breadstick.notify(
    //     ({ h, onClose }) => {
    //       return h(
    //         Notification,
    //         {
    //           props: {
    //             type: "danger",
    //             title: "Error",
    //             close: onClose
    //           }
    //         },
    //         "Unable to load images for this product"
    //       );
    //     },
    //     {
    //       position: "top-right"
    //     }
    //   );
    // }
  },
  methods: {
    newImage: function() {
      this.editingImages = [];
      this.addEditImagePanelShowing = true;
    },
    editImage: function(image) {
      this.editingImages = [image];
      this.addEditImagePanelShowing = true;
    },
    closeImagePanel: async function() {
      this.addEditImagePanelShowing = false;
      await this.uploadImage(this.editingImages);
    },
    uploadImage: async function(newImages) {
      if (newImages.length == 1 && newImages[0].attachment_id != null) {
        try {
          await this.ProductService.updateProductAttachment(
            this.product.product_code,
            newImages[0].attachment_id,
            newImages[0]
          );
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Update Success",
                    close: onClose
                  }
                },
                "Image metadata was updated successfully"
              );
            },
            {
              position: "top-right"
            }
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Update Error",
                    close: onClose
                  }
                },
                "There was a problem updating your image, please try again later"
              );
            },
            {
              position: "top-right"
            }
          );
        }
      } else {
        newImages.forEach(async image => {
          try {
            let imageData = {
              filename: image.file.name,
              content_type: image.file.type,
              attachment_type: "Image",
              file_size: image.file.size,
              name: image.name,
              description: image.description
            };

            let createResult = await this.ProductService.createProductAttachment(
              this.product.product_code,
              imageData
            );
            
            let attachmentId = createResult.attachment_id;

            let accessUrl = createResult.access_url;
            let url = new URL(accessUrl);
            let accountUrl = url.origin;
            let sasToken = url.search;

            // Parse container
            let pathParts = url.pathname.split("/").filter(p => {
              return p && p.trim() !== "";
            });
            let container = pathParts.length > 0 ? pathParts[0] : "";

            // Create filename
            let nonContainerParts = this.$_.drop(pathParts, 1);
            let filename = nonContainerParts.join("/");

            let blobService = AzureStorage.Blob.createBlobServiceWithSas(
              accountUrl,
              sasToken
            );
            let customBlockSize =
              image.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 128;
            blobService.singleBlobPutThresholdInBytes = customBlockSize;

            this.isUploading = true;
            let uploadSummary = blobService.createBlockBlobFromBrowserFile(
              container,
              filename,
              image.file,
              { blockSize: customBlockSize },
              async (error, result, response) => {
                this.isUploading = false;
                if (error) {
                  // Upload blob failed
                  this.$breadstick.notify(
                    ({ h, onClose }) => {
                      return h(
                        Notification,
                        {
                          props: {
                            type: "danger",
                            title: "Upload Error",
                            close: onClose
                          }
                        },
                        "There was a problem uploading your image, please try again later"
                      );
                    },
                    {
                      position: "top-right"
                    }
                  );
                  this.$delete(this.images, arrayIndex);
                } else {
                  let updateBody = {
                    file_status: "Available"
                  };
                  await this.ProductService.updateProductAttachment(
                    this.product.product_code,
                    attachmentId,
                    updateBody
                  );
                  this.images.push(createResult);
                  let arrayIndex = this.$_.findIndex(this.images, a => {
                    return a.attachment_id == createResult.attachment_id;
                  });
                  this.images[arrayIndex].is_uploading = false;
                  this.images[arrayIndex].file_status = "Available";
                  // this.$forceUpdate();
                  this.$breadstick.notify(
                    ({ h, onClose }) => {
                      return h(
                        Notification,
                        {
                          props: {
                            title: "Image Uploaded",
                            close: onClose
                          }
                        },
                        "Your image was uploaded successfully"
                      );
                    },
                    {
                      position: "top-right"
                    }
                  );
                }
                this.uploadProgress = 0;
              }
            );

            uploadSummary.on("progress", () => {
              this.uploadProgress = parseFloat(
                uploadSummary.getCompletePercent()
              );
            });
          } catch (err) {
            console.error(err);
            this.$breadstick.notify(
              ({ h, onClose }) => {
                return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Upload Error",
                      close: onClose
                    }
                  },
                  "There was a problem creating your attachment, please try again later"
                );
              },
              {
                position: "top-right"
              }
            );
          }
        });
      }
      this.attachment = {};
    },
    getFileSize(sizeBytes) {
      let sizeKb = sizeBytes / 1024;
      return sizeKb > 1023
        ? `${(sizeKb / 1024).toFixed(2)} MB`
        : `${sizeKb.toFixed(2)} KB`;
    },
    handleDeleteImage: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;

        try {
          await this.ProductService.deleteProductAttachment(
            this.product.product_code,
            this.confirmAlert.data.attachment_id
          );
          let arrayIndex = this.$_.findIndex(this.images, a => {
            return a.attachment_id == this.confirmAlert.data.attachment_id;
          });

          this.$delete(this.images, arrayIndex);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Image Deleted",
                    close: onClose
                  }
                },
                "Your image was deleted successfully"
              );
            },
            {
              position: "top-right"
            }
          );
        } catch (err) {
          console.error(err);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Delete Error",
                    close: onClose
                  }
                },
                "There was a problem deleting your image, please try again later"
              );
            },
            {
              position: "top-right"
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: ""
      };
    },
    deleteImage: function(image) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteImage,
        message: `Are you sure you want do delete '${image.name ||
          image.original_filename}'? Cannot be undone`,
        showing: true,
        data: image
      };
    }
  }
};
</script>